/* You can add global styles to this file, and also import other style files */
// Import Pico
@import "@picocss/pico/scss/pico";

body {
  margin: 0px;
}


// Theme customization
.warning {
  color: $amber-500;
}

.danger {
  color: $red-500;
}

